<template>
  <div class="cashback">
    <div class="cashback__content">
      <div class="cashback__title">
        {{$t('dialog_cashback.top_up')}}
      </div>
      <div class="cashback__steps">
        <div class="cashback__step">
          <div class="cashback__step-title">
            1. {{$t('dialog_cashback.how_to_top')}}
          </div>
          <div class="cashback__step-content">
            <div class="cashback__options">
              <label
                  v-if="role === 'finance' || role === 'admin'"
                  class="cashback__options-item"
                  :class="{'cashback__options-item--active' : selectedPayType === 'manual'}"
              >
                <input
                    type="radio"
                    v-model="selectedPayType"
                    :value="'manual'"
                >
                <img
                    class="cashback__options-icon"
                    :src="getPic(['icon-coins.svg', 'icon-coins-usa.svg'])"
                    alt="main balance"
                >
                <span class="cashback__options-info">
                  <span class="cashback__options-value">
                    {{$t('dialog_cashback.replenishment')}}
                  </span>
                  <span class="cashback__options-title">
                     {{$t('dialog_cashback.manual_replenishment')}}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="cashback__step">
          <div class="cashback__step-title">
            2. {{$t('dialog_cashback.account_link')}}
          </div>
          <div class="cashback__step-content">
            <div class="cashback__options">
              <label
                  class="cashback__options-item"
                  :class="{'cashback__options-item--active' : selectedBalance === 'RUR'}"
              >
                <input
                    type="radio"
                    v-model="selectedBalance"
                    value="RUR"
                >
                <img
                    class="cashback__options-icon"
                    :src="getPic(['icon-wallet.svg', 'icon-wallet-red.svg'])"
                    alt="main balance"
                >
                <span class="cashback__options-info">
                  <span class="cashback__options-title">
                    {{$t('dialog_cashback.cashback')}}
                  </span>
                  <span class="cashback__options-value">
                     <!-- {{ activeAccount.cashback_balance ? numberWithSpaces(activeAccount.cashback_balance).split('.')[0] : 0 }} {{ $t('currency.currency_symbol') }} -->
                     {{ activeAccount.cashback_balance ? locale ==='USA' ? numberWithSpacesFix2(activeAccount.cashback_balance) : numberWithSpaces(activeAccount.cashback_balance).split('.')[0] : 0 }} {{ $t('currency.currency_symbol') }}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="cashback__step">
          <div class="cashback__step-title">
            3. {{$t('dialog_cashback.transfer_details')}}
          </div>
          <div class="cashback__step-content cashback__step-content--three">
            <div
                class="form-input"
                :class="[{'form-input--full' : form.sum},{'form-input--error' : errors.sum}]"
            >
              <input
                  v-model="form.sum"
                  required
                  @blur="checkValue"
              >
              <span class="form-input__label">{{$t('dialog_cashback.transfer_amount')}}</span>
              <div class="form-input__icon">
                {{ $t('currency.currency_symbol') }}
              </div>
              <div
                  v-if="errors.sum"
                  class="form-input__error"
                  v-text="errors.sum"
              />
            </div>
            <div v-if="role !== 'finance' && role !=='admin'" class="cashback__step-tip">
              {{ $t('currency.minimum_payment') }} 30 000 {{ $t('currency.currency_symbol') }} 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cashback__final">
      <div class="cashback__final-title">
        {{ $t('dialog_cashback.total_amount') }}
      </div>
      <div v-if="form.sum" class="cashback__final-value">
        {{ form.sum }} {{ $t('currency.currency_symbol') }}
      </div>
      <ButtonElement
          :text="$t('button_props.add')"
          :disabled="!form.sum || !selectedPayType || !selectedBalance || !!errors.sum"
          @click-button="transferAmount"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import numberWithSpaces from '@/mixin/numberWithSpaces'

export default {
  name: 'Cashback',
  components: {
    ButtonElement,
  },
  mixins: [formValidate, numberWithSpaces],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      selectedPayType: null,
      selectedBalance: null,
      form: {
        name_legal_entity: '',
        inn: '',
        kpp: '',
        legal_address: '',
        phone: '',
        gateway_code: '',
        sum: '',
      },
      errors: {
        name_legal_entity: '',
        inn: '',
        kpp: '',
        legal_address: '',
        phone: '',
        gateway_code: '',
        sum: '',
      },
      minPayment: 30000,
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    userData() {
      return this.$store.state.Auth.user
    },
    role() {
      let role = ''
      if (this.userData) {
        role = this.userData.role
      }
      return role
    },
  },
  methods: {
    getPic(data) {
      let result = ''
      if(this.locale==='RU') {
        result = require(`@/assets/icons/${data[0]}`)
      } else {
        result = result = require(`@/assets/icons/${data[1]}`)
      }
      return result
    },
    checkValue() {
      const r = /^-{0,1}\d+\.{0,1}\d*$/;
      if (!r.test(this.form.sum)) {
       
        this.errors.sum = this.$t('dialog_cashback.error_sum')
      } else {
        this.errors.sum = ''
      }
    },
    transferAmount() {
      if (this.selectedPayType && this.selectedBalance && this.form.sum) {
        const data = {
          data: {
            business_account_id: this.activeAccount.id,
            sum: this.form.sum,
            type_id: 11
          },
          token: localStorage.getItem('token')
        }
        this.$store.dispatch('Balance/addManualPayment', data)
          .then(() => {
            this.$store.dispatch('Dialog/closeDialog')
            this.$notify({
              message: this.$t('dialog_cashback.success_message'),
              type: 'success'
            });
          })
      }
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cashback {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 6.5rem;
    color: #000;
  }

  &__content {
    padding: 7rem 6rem;
  }

  &__final {
    width: 100%;
    background: #EFF1F4;
    padding: 3.3rem 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      white-space: nowrap;
      font-size: 1.8rem;
      line-height: 2rem;
      color: #333;
      margin-right: 2rem;
    }

    &-value {
      font-weight: bold;
      font-size: 3.5rem;
      line-height: 4.1rem;
      margin-right: 2rem;
    }

    .button {
      width: 27rem;
      height: 4.5rem;
    }
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      &--three {
        width: 40rem;
      }

      .el-dialog__body {
        word-break: normal !important;
      }

      .form-input__error {
        word-break: normal !important;
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }

    &-tip {
      margin-top: 3rem;
      color: $gray;
    }

    &-account-selector {
      width: 40rem;
      margin-bottom: 0.6rem;
      position: relative;

      &-value {
        border: 1px solid #BFC2C6;
        border-radius: 2px;
        height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem 0 1.5rem;
        cursor: pointer;
      }

      &-name {
        display: flex;
        align-items: center;
        font-weight: 700;
      }

      &-options {
        position: absolute;
        border: 1px solid #BFC2C6;
        border-radius: 2px;
        background: #fff;
        z-index: 10;
        width: 100%;
        left: 0;
        top: calc(100% + 5px);
      }

      &-item {
        padding: 0.7rem 1.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s ease-out;
        font-size: 1.4rem;

        span:first-of-type {
          font-weight: 700;
        }

        &:hover {
          color: var(--primary);
        }
      }
    }

    &-amount {
      position: relative;
      width: 38.7rem;

      &-input {
        background: #FFFFFF;
        border: 1px solid #BFC2C6;
        box-sizing: border-box;
        border-radius: 2px;
        height: 5rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #333;
        padding: 0 2.4rem 0 1.4rem;
        width: 100%;
      }

      &-currency {
        position: absolute;
        right: 1.4rem;
        top: 1.6rem;
        color: #7F858D;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }
  }

  &__options {
    display: flex;

    &-item {
      position: relative;
      background: #FFFFFF;
      border-radius: 6px;
      border: 2px solid #fff;
      box-shadow: 0 3px 10px rgba(25, 47, 79, 0.12);
      width: 24rem;
      height: 9.2rem;
      display: flex;
      align-items: center;
      padding: 0 1.8rem;
      margin-right: 2.5rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &::before {
        content: '';
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        background: url("../../assets/icons/icon-done.svg") var(--primary) no-repeat center;
        background-size: 80%;
        position: absolute;
        top: 0.7rem;
        right: 0.7rem;
        opacity: 0;
      }

      &--active {
        box-shadow: none;
        border: 2px solid var(--primary);

        &::before {
          opacity: 1;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    &-icon {
      width: 3.3rem;
      margin-right: 1.4rem;
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    &-value {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      margin-top: 0.7rem;
    }
  }
}
</style>
